import '../javascripts/jquery'
import '../javascripts/ie'
import 'bootstrap'
import Rails from '@rails/ujs'

import '../javascripts/lp'

// eslint-disable-next-line no-underscore-dangle
if (!window._rails_loaded) {
  Rails.start()
}
